import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import TrendingIcon from "../assets/images/icons/trend/trending.svg"
import IncreasingIcon from "../assets/images/icons/trend/increasing.svg"
import SteadyIcon from "../assets/images/icons/trend/steady.svg"
import DecreasingIcon from "../assets/images/icons/trend/decreasing.svg"
import FadingIcon from "../assets/images/icons/trend/fading.svg"

export const CurrencyMap = {
  "N/A": "?", // Unkown
  USD: "$", // US Dollar
  EUR: "€", // Euro
  GBP: "£", // British Pound
  JPY: "¥", // Japanese Yen
  AUD: "A$", // Australian Dollar
  CAD: "C$", // Canadian Dollar
  CHF: "CHF", // Swiss Franc
  CNY: "¥", // Chinese Yuan
  SEK: "kr", // Swedish Krona
  NZD: "NZ$", // New Zealand Dollar
  INR: "₹", // Indian Rupee
  RUB: "₽", // Russian Ruble
  ZAR: "R", // South African Rand
  BRL: "R$", // Brazilian Real
  MXN: "Mex$", // Mexican Peso
  TRY: "₺", // Turkish Lira
  TL: "₺", // Turkish Lira
  KRW: "₩", // South Korean Won
  SGD: "S$", // Singapore Dollar
  HKD: "HK$", // Hong Kong Dollar
  NOK: "kr", // Norwegian Krone
  DKK: "kr", // Danish Krone
  PLN: "zł", // Polish Zloty
  THB: "฿", // Thai Baht
  MYR: "RM", // Malaysian Ringgit
  IDR: "Rp", // Indonesian Rupiah
  SAR: "﷼", // Saudi Riyal
  AED: "د.إ", // UAE Dirham
  ARS: "$", // Argentine Peso
  EGP: "£", // Egyptian Pound
  PHP: "₱", // Philippine Peso
  VND: "₫" // Vietnamese Dong
}

export enum ReportUploadColumnTypes {
  PERIOD = "period",
  PLATFORM = "platform",
  TRANSACTION_DATE = "transaction_date",
  SONG_TITLE = "song_title",
  ISRC = "isrc",
  TERRITORY = "territory",
  QUANTITY = "quantity",
  CURRENCY = "currency",
  SEGMENT = "segment",
  SEGMENT_DESCRIPTION = "segment_description",
  NET_QUANTITY = "net_quantity",
  GROSS_PROGRESS = "gross_progress",
  NET_PROGRESS = "net_progress",
  SPLIT_RATE = "split_rate"
}

export enum TrendChoices {
  FADING = -2,
  DECREASING = -1,
  STEADY = 0,
  INCREASING = 1,
  TRENDING = 2
}

export const TrendOptions: KSelectOption[] = [
  { value: TrendChoices.TRENDING, label: "Trending", icon: TrendingIcon },
  { value: TrendChoices.INCREASING, label: "Increasing", icon: IncreasingIcon },
  { value: TrendChoices.STEADY, label: "Steady", icon: SteadyIcon },
  { value: TrendChoices.DECREASING, label: "Decreasing", icon: DecreasingIcon },
  { value: TrendChoices.FADING, label: "Fading", icon: FadingIcon }
]
