import { FETCH_AFFILIATE_RELATIONSHIPS, FETCH_AFFILIATES, FETCH_COMPANIES, FETCH_COMPANY_BREAKDOWNS } from "./actions"
import { PaginatedData } from "../../types/root"
import { Affiliate, AffiliateRelationship, AffiliatesActionProps, AffiliatesProps } from "types/affiliates"

export const initialAffiliateState: AffiliatesProps = {
  paginatedAffiliateList: new PaginatedData<Affiliate>(),
  paginatedAffiliateRelationshipList: new PaginatedData<AffiliateRelationship>()
}

const affiliates = (state = initialAffiliateState, action: AffiliatesActionProps) => {
  switch (action.type) {
    case FETCH_AFFILIATES: {
      const paginatedAffiliateList =
        (action.payload?.paginatedAffiliateList as PaginatedData<Affiliate>) || new PaginatedData<Affiliate>()
      return {
        ...state,
        paginatedAffiliateList
      }
    }
    case FETCH_AFFILIATE_RELATIONSHIPS: {
      const paginatedAffiliateRelationshipList =
        (action.payload?.paginatedAffiliateRelationshipList as PaginatedData<AffiliateRelationship>) ||
        new PaginatedData<AffiliateRelationship>()
      return {
        ...state,
        paginatedAffiliateRelationshipList
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default affiliates
