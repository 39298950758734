import {
  FETCH_ACCOUNT_TYPES,
  FETCH_CONTACT_TYPES,
  FETCH_DISTRIBUTORS,
  FETCH_PIPELINE_ACTIONS,
  FETCH_SERVICES,
  FETCH_TAGS,
  FETCH_UNIT_PRICES
} from "./actions"
import { ConstantsActionProps, ConstantsProps } from "types/constants"

export const initialConstantsState: ConstantsProps = {
  accountTypes: [],
  contactTypes: [],
  services: [],
  tags: [],
  unitPrices: [],
  distributors: [],
  pipelineActions: []
}

const constants = (state = initialConstantsState, action: ConstantsActionProps) => {
  switch (action.type) {
    case FETCH_ACCOUNT_TYPES: {
      const accountTypes = action.payload?.accountTypes || []
      return {
        ...state,
        accountTypes
      }
    }
    case FETCH_CONTACT_TYPES: {
      const contactTypes = action.payload?.contactTypes || []
      return {
        ...state,
        contactTypes
      }
    }
    case FETCH_SERVICES: {
      const services = action.payload?.services || []
      return {
        ...state,
        services
      }
    }
    case FETCH_TAGS: {
      const tags = action.payload?.tags || []
      return {
        ...state,
        tags
      }
    }
    case FETCH_DISTRIBUTORS: {
      const distributors = action.payload?.distributors || []
      return {
        ...state,
        distributors
      }
    }
    case FETCH_PIPELINE_ACTIONS: {
      const pipelineActions = action.payload?.pipelineActions || []
      return {
        ...state,
        pipelineActions
      }
    }
    case FETCH_UNIT_PRICES: {
      const unitPrices = action.payload?.unitPrices || []
      return {
        ...state,
        unitPrices
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default constants
