import { FETCH_AGREEMENT_UPLOADS, FETCH_CONFIRMED_OFFERS } from "./actions"
import { PaginatedData } from "../../types/root"
import { AgreementUpload, ConfirmedOffer, ReportsActionProps, ReportsProps } from "../../types/reports"

export const initialReportsState: ReportsProps = {
  paginatedConfirmedOfferList: new PaginatedData<ConfirmedOffer>(),
  paginatedAgreementUploadList: new PaginatedData<AgreementUpload>()
}

const reports = (state = initialReportsState, action: ReportsActionProps) => {
  switch (action.type) {
    case FETCH_CONFIRMED_OFFERS: {
      const paginatedConfirmedOfferList =
        (action.payload?.paginatedConfirmedOfferList as PaginatedData<ConfirmedOffer>) ||
        new PaginatedData<ConfirmedOffer>()
      return {
        ...state,
        paginatedConfirmedOfferList
      }
    }
    case FETCH_AGREEMENT_UPLOADS: {
      const paginatedAgreementUploadList =
        (action.payload?.paginatedAgreementUploadList as PaginatedData<AgreementUpload>) ||
        new PaginatedData<AgreementUpload>()
      return {
        ...state,
        paginatedAgreementUploadList
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default reports
