import { createContext, ReactElement, useReducer } from "react"
import usersReducer, { initialUsersState } from "../store/reducers/users"
import axios from "utils/axios"
import { FETCH_CLICKUP_USERS, FETCH_USERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText, toastMessage } from "../utils"
import { User, UsersContextType } from "../types/users"

const UsersContext = createContext<UsersContextType | null>(null)

export const UsersProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(usersReducer, initialUsersState)

  const fetchUsers = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/users/?limit=${limit}&offset=${offset}${getFilterText(filter)}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_USERS,
      payload: { ...state, paginatedUsers: mapPaginatedDataToCamelCase<PaginatedData<User>>(response.data) }
    })
  }

  const fetchClickupUsers = async () => {
    const response = await axios(`/users/clickup_users/`, { method: "get" })

    dispatch({
      type: FETCH_CLICKUP_USERS,
      payload: { ...state, clickupUsers: mapPaginatedDataToCamelCase<PaginatedData<User>>(response.data) }
    })
  }

  const updateRole = async (userId: number, roleId: number, value: boolean) => {
    const response = await axios(`/users/update_role/`, {
      method: "put",
      data: { user_id: userId, role_id: roleId, value }
    })

    if (!response.data.success) {
      toastMessage("Error saving role", "error")
      dispatch({
        type: FETCH_USERS,
        payload: { ...state }
      })
      return
    }

    const updatedResults = [
      ...state.paginatedUsers.results.map((result) => {
        if (result.id === userId) {
          if (value) {
            result.roleIds = [...(result.roleIds || []), roleId]
          } else {
            result.roleIds = [...(result.roleIds || []).filter((id) => roleId !== id)]
          }
        }
        return result
      })
    ]

    toastMessage("Success", "success")
    dispatch({
      type: FETCH_USERS,
      payload: { ...state, paginatedUsers: { ...state.paginatedUsers, results: [...updatedResults] } }
    })
  }

  return (
    <UsersContext.Provider value={{ ...state, fetchUsers, updateRole, fetchClickupUsers }}>
      {children}
    </UsersContext.Provider>
  )
}

export default UsersContext
