import { createContext, ReactElement, useReducer } from "react"
import spotifyArtistRequestReducer, { initialSpotifyArtistRequestState } from "../store/reducers/spotifyArtistRequest"
import axios from "utils/axios"
import { FETCH_SPOTIFY_ARTIST_REQUESTS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { SpotifyArtistRequest, SpotifyArtistRequestContextType } from "../types/spotifyArtistRequest"

const SpotifyArtistRequestContext = createContext<SpotifyArtistRequestContextType | null>(null)

export const SpotifyArtistRequestProviderProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(spotifyArtistRequestReducer, initialSpotifyArtistRequestState)

  const fetchSpotifyArtistRequests = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/reports/artist/?limit=${limit}&offset=${offset}${getFilterText(filter)}${
        orderBy ? `&order_by=${orderBy}` : ""
      }`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_SPOTIFY_ARTIST_REQUESTS,
      payload: {
        ...state,
        paginatedSpotifyArtistRequests: mapPaginatedDataToCamelCase<PaginatedData<SpotifyArtistRequest>>(response.data)
      }
    })
  }

  const updateSpotifyArtistStatus = async (id: number, value: string, key: string) => {
    const response = await axios(`/reports/artist/update_status/`, {
      method: "put",
      data: { id, value, key }
    })

    if (!response.data.success) {
      return
    }

    const results = [...state.paginatedSpotifyArtistRequests.results].map((sar: SpotifyArtistRequest) => {
      if (sar.id === id) {
        if (key === "currency") {
          sar.royaltyReport.currency = value
        } else {
          //@ts-ignore
          sar[key] = value
        }
      }
      return sar
    })

    dispatch({
      type: FETCH_SPOTIFY_ARTIST_REQUESTS,
      payload: {
        ...state,
        paginatedSpotifyArtistRequests: { ...state.paginatedSpotifyArtistRequests, results }
      }
    })
  }

  const createConfirmedOffer = async (
    id: number,
    checkStreamFromReport: boolean,
    sendToInbox: boolean,
    trackIsrcList?: string[]
  ) => {
    const checkStreamParam = checkStreamFromReport ? "&stream_from_report=1" : ""
    const sendToInboxParam = sendToInbox ? "&send_inbox=1" : ""
    const trackIsrcListParam = trackIsrcList ? `&isrc_list=${trackIsrcList?.join(",")}` : ""
    const response = await axios(
      `/reports/artist/create_confirmed_offer/?id=${id}${checkStreamParam}${sendToInboxParam}${trackIsrcListParam}`,
      {
        method: "post",
        data: { id }
      }
    )

    if (!response.data.success) {
      return false
    }

    const results = [...state.paginatedSpotifyArtistRequests.results].map((sar: SpotifyArtistRequest) => {
      if (sar.id === id) sar.status = "created_confirmed_offer"
      return sar
    })

    dispatch({
      type: FETCH_SPOTIFY_ARTIST_REQUESTS,
      payload: {
        ...state,
        paginatedSpotifyArtistRequests: { ...state.paginatedSpotifyArtistRequests, results }
      }
    })

    return true
  }

  return (
    <SpotifyArtistRequestContext.Provider
      value={{ ...state, fetchSpotifyArtistRequests, updateSpotifyArtistStatus, createConfirmedOffer }}
    >
      {children}
    </SpotifyArtistRequestContext.Provider>
  )
}

export default SpotifyArtistRequestContext
