import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_AGREEMENT_UPLOADS, FETCH_CONFIRMED_OFFERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import {
  ReportsContextType,
  ConfirmedOffer,
  ConfirmedOfferTypes,
  ConfirmedOffersFilterType,
  AgreementUploadFilterType,
  AgreementUpload
} from "types/reports"
import reportsReducer, { initialReportsState } from "store/reducers/reports"
import { getFilterText } from "utils"

const ReportsContext = createContext<ReportsContextType | null>(null)

export const ReportsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reportsReducer, initialReportsState)

  const fetchConfirmedOfferList = async (
    limit: number,
    offset: number,
    isPublic: boolean,
    filter?: ConfirmedOffersFilterType,
    orderBy?: string,
    id?: number,
    userId?: number
  ) => {
    const idParam = id ? `&id=${id}` : ""
    const userIdParam = userId ? `&user_id=${userId}` : ""
    const orderByParam = orderBy ? `&order_by=${orderBy}` : ""

    const tagIncludedLength = filter?.tagsIncluded?.length || 0
    const tagExcludedLength = filter?.tagsExcluded?.length || 0
    const tagsIncludedParam = tagIncludedLength > 0 ? `&tags_included=${filter?.tagsIncluded.join(",")}` : ""
    const tagsExcludedParam = tagExcludedLength > 0 ? `&tags_excluded=${filter?.tagsExcluded.join(",")}` : ""
    const filteredFilter = { ...filter, tagsIncluded: undefined, tagsExcluded: undefined }

    const params = `?limit=${limit}&offset=${offset}&scouting=1${orderByParam}${idParam}${userIdParam}${getFilterText(
      filteredFilter
    )}${tagsIncludedParam}${tagsExcludedParam}`

    const baseUrl = isPublic ? "/public/offers/confirmed_offers" : `/reports/confirmed-offers`
    const url = `${baseUrl}/${params}`
    const response = await axios(url, { method: "get" })

    dispatch({
      type: FETCH_CONFIRMED_OFFERS,
      payload: {
        ...state,
        paginatedConfirmedOfferList: mapPaginatedDataToCamelCase<PaginatedData<ConfirmedOffer>>(response.data)
      }
    })
  }

  const updateConfirmedOffer = async (
    id: number,
    approved: boolean,
    type: string,
    splitRate?: number,
    offerUsd?: number
  ) => {
    const response = await axios(`/reports/confirmed-offers/save/`, {
      method: "put",
      data: { id, offer_usd: offerUsd, approved, type, split_rate: splitRate }
    })

    if (!response.data.success) {
      return false
    }

    const results = [...state.paginatedConfirmedOfferList.results].map((confirmedOffer: ConfirmedOffer) => {
      if (confirmedOffer.id === id) {
        if (offerUsd && confirmedOffer.type === ConfirmedOfferTypes.ADVANCE) {
          confirmedOffer.advanceOfferUsd = offerUsd
        } else if (offerUsd && confirmedOffer.type === ConfirmedOfferTypes.BUYOUT) {
          confirmedOffer.buyoutOfferUsd = offerUsd
        }

        confirmedOffer.approved = approved
        confirmedOffer.type = type
        confirmedOffer.splitRate = splitRate
      }
      return confirmedOffer
    })

    dispatch({
      type: FETCH_CONFIRMED_OFFERS,
      payload: {
        ...state,
        paginatedConfirmedOfferList: { ...state.paginatedConfirmedOfferList, results }
      }
    })

    return true
  }

  const updateSongSelectionOffer = async (confirmedOfferId: number, songSelectionId: number, offer: number) => {
    const response = await axios(`/reports/confirmed-offers/song_selection_offer/`, {
      method: "put",
      data: { confirmed_offer_id: confirmedOfferId, song_selection_id: songSelectionId, offer_usd: offer }
    })

    if (!response.data.success) {
      return false
    }

    return true
  }

  const fetchAgreementUploadList = async (
    limit: number,
    offset: number,
    filter?: AgreementUploadFilterType,
    orderBy?: string
  ) => {
    const url = `/reports/royalty/agreement_uploads/?limit=${limit}&offset=${offset}&artist_id=${filter?.artistId}`

    const response = await axios(url, { method: "get" })

    dispatch({
      type: FETCH_AGREEMENT_UPLOADS,
      payload: {
        ...state,
        paginatedAgreementUploadList: mapPaginatedDataToCamelCase<PaginatedData<AgreementUpload>>(response.data)
      }
    })
  }

  const uploadAgreementFile = async (formData: any, artistId: number) => {
    const url = `/reports/royalty/upload_agreement_file/?artist_id=${artistId}`

    const response = await axios.post(url, formData)

    if (!response.data.result) {
      return false
    }

    const agreementUpload: AgreementUpload = mapToCamelCase(response.data.result)
    const results = [agreementUpload, ...state.paginatedAgreementUploadList.results]

    dispatch({
      type: FETCH_AGREEMENT_UPLOADS,
      payload: {
        ...state,
        paginatedAgreementUploadList: { ...state.paginatedAgreementUploadList, results }
      }
    })

    return true
  }

  return (
    <ReportsContext.Provider
      value={{
        ...state,
        fetchConfirmedOfferList,
        updateConfirmedOffer,
        updateSongSelectionOffer,
        fetchAgreementUploadList,
        uploadAgreementFile
      }}
    >
      {children}
    </ReportsContext.Provider>
  )
}

export default ReportsContext
