import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_AFFILIATES, FETCH_AFFILIATE_RELATIONSHIPS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { Affiliate, AffiliateRelationship, AffiliatesContextType } from "types/affiliates"
import affiliatesReducer, { initialAffiliateState } from "store/reducers/affiliates"

const AffiliatesContext = createContext<AffiliatesContextType | null>(null)

export const AffiliatesProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(affiliatesReducer, initialAffiliateState)

  const fetchAffiliateList = async (
    limit: number,
    offset: number,
    orderBy?: string,
    search?: string,
    identifier?: string,
    platform?: string
  ) => {
    const searchParam = `${search ? `&search=${search}` : ""}`
    const identifierParam = `${identifier ? `&identifier=${identifier}` : ""}`
    const platformParam = `${platform ? `&platform=${platform}` : ""}`
    const orderByParam = `${orderBy ? `&order_by=${orderBy}` : ""}`
    const response = await axios(
      `/affiliates/?limit=${limit}&offset=${offset}${searchParam}${identifierParam}${platformParam}${orderByParam}`,
      {
        method: "get"
      }
    )

    dispatch({
      type: FETCH_AFFILIATES,
      payload: {
        ...state,
        paginatedAffiliateList: mapPaginatedDataToCamelCase<PaginatedData<Affiliate>>(response.data)
      }
    })
  }

  const fetchAffiliateRelationshipList = async (
    limit: number,
    offset: number,
    orderBy?: string,
    affiliateId?: number,
    followedById?: number,
    search?: string
  ) => {
    const affiliateIdParam = affiliateId ? `&affiliate_id=${affiliateId}` : ""
    const followedByIdParam = followedById ? `&followed_by_id=${followedById}` : ""
    const searchParam = search ? `&search=${search}` : ""

    const orderByParam = `${orderBy ? `&order_by=${orderBy}` : ""}`
    const response = await axios(
      `/affiliate/relationships/?limit=${limit}&offset=${offset}${followedByIdParam}${affiliateIdParam}${searchParam}${orderByParam}`,
      {
        method: "get"
      }
    )

    dispatch({
      type: FETCH_AFFILIATE_RELATIONSHIPS,
      payload: {
        ...state,
        paginatedAffiliateRelationshipList: mapPaginatedDataToCamelCase<PaginatedData<AffiliateRelationship>>(
          response.data
        )
      }
    })
  }

  return (
    <AffiliatesContext.Provider
      value={{
        ...state,
        fetchAffiliateList,
        fetchAffiliateRelationshipList
      }}
    >
      {children}
    </AffiliatesContext.Provider>
  )
}

export default AffiliatesContext
