import { createContext, ReactElement, useReducer } from "react"
import constantsReducer, { initialConstantsState } from "../store/reducers/constants"
import axios from "utils/axios"
import {
  CREATE_TAG,
  FETCH_ACCOUNT_TYPES,
  FETCH_CONTACT_TYPES,
  FETCH_DISTRIBUTORS,
  FETCH_PIPELINE_ACTIONS,
  FETCH_SERVICES,
  FETCH_TAGS,
  FETCH_UNIT_PRICES
} from "../store/reducers/actions"
import { mapListToCamelCase, mapToSnakeCase } from "../utils/caseTransformation"
import { ConstantsContextType, Tag, UnitPrice } from "types/constants"
import { toastMessage } from "utils"

const ConstantsContext = createContext<ConstantsContextType | null>(null)

export const ConstantsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(constantsReducer, initialConstantsState)

  const fetchAccountTypes = async () => {
    const response = await axios(`/constants/account_types/`, { method: "get" })

    dispatch({
      type: FETCH_ACCOUNT_TYPES,
      payload: { ...state, accountTypes: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchContactTypes = async () => {
    const response = await axios(`/constants/contact_types/`, { method: "get" })

    dispatch({
      type: FETCH_CONTACT_TYPES,
      payload: { ...state, contactTypes: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchServices = async () => {
    const response = await axios(`/constants/services/`, { method: "get" })

    dispatch({
      type: FETCH_SERVICES,
      payload: { ...state, services: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchDistributors = async () => {
    const response = await axios(`/constants/distributors/`, { method: "get" })

    dispatch({
      type: FETCH_DISTRIBUTORS,
      payload: { ...state, distributors: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchTags = async () => {
    const response = await axios(`/constants/tags/`, { method: "get" })

    dispatch({
      type: FETCH_TAGS,
      payload: { ...state, tags: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchUnitPrices = async () => {
    const response = await axios(`/constants/unit_prices/`, { method: "get" })

    dispatch({
      type: FETCH_UNIT_PRICES,
      payload: { ...state, unitPrices: mapListToCamelCase(response.data.results) }
    })
  }

  const fetchPipelineActions = async () => {
    const response = await axios(`/constants/pipeline_actions/`, { method: "get" })

    dispatch({
      type: FETCH_PIPELINE_ACTIONS,
      payload: { ...state, pipelineActions: mapListToCamelCase(response.data.results) }
    })
  }

  const saveUnitPrice = async (unitPrice: UnitPrice, isMin: boolean) => {
    const response = await axios(`/constants/update_unit_price/`, { method: "put", data: mapToSnakeCase(unitPrice) })

    if (response.data.success) {
      toastMessage(`Successfully saved unit price`, "success")
    } else {
      toastMessage(`Error saving unit price: ${response.data.message}`, "error")
      return false
    }

    const updatedUnitPrice = [
      ...state.unitPrices.map((up) => {
        if (up.id === unitPrice.id) {
          if (isMin) {
            up.minPrice = unitPrice.minPrice
          } else {
            up.maxPrice = unitPrice.maxPrice
          }
        }
        return up
      })
    ]

    dispatch({
      type: FETCH_UNIT_PRICES,
      payload: { ...state, unitPrices: updatedUnitPrice }
    })

    return true
  }

  return (
    <ConstantsContext.Provider
      value={{
        ...state,
        fetchAccountTypes,
        fetchContactTypes,
        fetchServices,
        fetchTags,
        fetchUnitPrices,
        saveUnitPrice,
        fetchDistributors,
        fetchPipelineActions
      }}
    >
      {children}
    </ConstantsContext.Provider>
  )
}

export default ConstantsContext
