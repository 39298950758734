import { User } from "./users"
import { Artist } from "./artists"
import { PaginatedData } from "./root"
import { SpotifyArtistRequest } from "./spotifyArtistRequest"

export enum ReportTabs {
  UNDEFINED = 0,
  TRACK_VALUATION_BUBBLE = 1,
  LTM_AND_FORECAST = 2,
  INCOME_AND_FORECAST = 3,
  MONTHLY_INCOME_AND_LISTENERS = 4,
  TOTAL_INCOME_AND_LISTENERS = 5,
  PLATFORM_AND_TERRITORY = 6,
  DISTRIBUTOR_PERCENTAGE = 7,
  TRACKS_INCOME_AND_LISTENER_PERCENTAGE = 8,
  PLATFORM_MONTHLY_INCOME = 9,
  PLATFORM_MONTHLY_LISTENER = 10,
  TRACK_TIMELINE = 11
}

export class AdminSongSelectionWidth {
  checkbox = "3%"
  link = "4%"
  isrc = ""
  title = "22%"
  albumId = ""
  ownership = "3%"
  userApproved = "3%"
  confirmedOffer = "6%"
  offerUsd = ""
  advanceUsd = "6%"
  buyoutUsd = "6%"
  releaseDAte = "7%"
  streamCount = "6%"
  streamDate = "7%"
  lmLsmLtmStream = "7%"
  lmLsmLtmIncome = "7%"
  Ltm456Income = "7%"
  reportLtmIncome = "7%"
}

export class UserSongSelectionWidth {
  checkbox = "4%"
  link = "6%"
  isrc = ""
  title = "36%"
  ownership = ""
  userApproved = ""
  albumId = ""
  confirmedOffer = "14%"
  offerUsd = ""
  advanceUsd = ""
  buyoutUsd = ""
  releaseDAte = "11%"
  streamCount = "9%"
  streamDate = ""
  lmLsmLtmStream = "10%"
  lmLsmLtmIncome = ""
  Ltm456Income = ""
  reportLtmIncome = "10%"
}

export enum ConfirmedOfferTypes {
  ADVANCE = "advance",
  BUYOUT = "buyout"
}

export enum RoyaltyReportTypes {
  INDIVIDUAL = "individual",
  LABEL = "label"
}

export type RoyaltyReportRow = {
  distributor: string
  period: string
  platform: string
  transactionDate: string
  songTitle: string
  isrc: string
  created: string
  territory: string
  quantity: number
  currency: string
  segment: string
  segmentDescription: string
  netQuantity: number
  grossProgress: number
  netProgress: number
  splitRate: number
  channelId: string
  isVideo: boolean
}

export type RoyaltyReport = {
  user: User
  userId: number
  sheetId: string
  spotifyId: string
  artist: Artist
  progress: number
  validIsrc: boolean
  created: string
  distributor: string
  type: string
  currency: string
  unitPrice: number
  error: string
  warning: string
  rawFileId: string
}

export interface ConfirmedOfferOption {
  id: number
  name: string
  value: string
  description: string
  multiplier: number
  type: string
  primary: boolean
}

export interface ConfirmedOffer {
  id: number
  spotifyId: string
  created: string
  advanceOfferUsd: number
  buyoutOfferUsd: number
  approved: boolean
  artist: Artist
  user: User
  userApproved: boolean
  selectedOption: ConfirmedOfferOption
  confirmedTotal: number
  type: string
  spotifyArtistAccount: SpotifyArtistRequest
  songCount?: number
  offerCount?: number
  splitRate?: number
}

export interface AgreementUpload {
  id: number
  user: User
  userId: string
  artistId: string
  sheetId: string
  progress: number
  created: string
}

export type ReportsContextType = {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
  fetchConfirmedOfferList: (
    limit: number,
    offset: number,
    isPublic: boolean,
    filter?: ConfirmedOffersFilterType,
    orderBy?: string,
    id?: number,
    userId?: number
  ) => Promise<void>
  updateConfirmedOffer: (
    id: number,
    approved: boolean,
    type: string,
    splitRate?: number,
    offerUsd?: number
  ) => Promise<boolean>
  updateSongSelectionOffer: (confirmedOfferId: number, songSelectionId: number, offer: number) => Promise<boolean>
  paginatedAgreementUploadList: PaginatedData<AgreementUpload>
  fetchAgreementUploadList: (
    limit: number,
    offset: number,
    filter?: AgreementUploadFilterType,
    orderBy?: string
  ) => Promise<void>
  uploadAgreementFile: (formData: any, artistId: number) => Promise<boolean>
}

export interface ReportsProps {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
  paginatedAgreementUploadList: PaginatedData<AgreementUpload>
}

export interface ReportsActionProps {
  type: string
  payload?: ReportsProps
}

export interface ConfirmedOfferTransferRequest {
  confirmedOfferId: number
  spotifyAccountId: number
  firstName: string
  lastName: string
  email: string
  password: string
  spotifyId: string
  allowedOfferLinkCode: string
  overrideAllowedOfferLinkCode: boolean
  duplicateOffer: boolean
  [key: string]: string | number | boolean
}

export type ConfirmedOfferSort = {
  id: number
  user_id: number
  spotify_id: number
  created: number
  advance_offer_usd: number
  buyout_offer_usd: number
  approved: number
  user_approved: number
  selected_option_id: number
  confirmed_total: number
  type: number
  user__username: number
  spotify_artist_account_id: number
  spotify_artist_account__report_uploaded: number
  spotify_artist_account__timeline: number
  [key: string]: number
}

export const initialConfirmedOfferSort: ConfirmedOfferSort = {
  id: -1,
  user_id: 0,
  spotify_id: 0,
  created: 0,
  advance_offer_usd: 0,
  buyout_offer_usd: 0,
  approved: 0,
  user_approved: 0,
  selected_option_id: 0,
  confirmed_total: 0,
  type: 0,
  user__username: 0,
  spotify_artist_account_id: 0,
  spotify_artist_account__report_uploaded: 0,
  spotify_artist_account__timeline: 0
}

export type ConfirmedOffersFilterType = {
  artistName: string
  artistSpotifyId: string
  username: string
  rawBuyoutOffer?: number[]
  rawAdvanceOffer?: number[]
  confirmedBuyoutOffer?: number[]
  confirmedAdvanceOffer?: number[]
  songCount?: number[]
  offerCount?: number[]
  country: string
  genre: string
  hotList?: boolean
  ignore?: boolean
  salesFunnel?: boolean
  contactStatus?: boolean
  accountType?: string
  service?: string
  tagsIncluded: string[]
  tagsExcluded: string[]
  emailExist?: boolean
  phoneExist?: boolean
}

export type ConfirmedOfferSongSelectionSort = {
  track_title: number
  isrc: number
  track_albumId: number
  selected: number
  ownership: number
  advanceOfferUsd: number
  buyoutOfferUsd: number
  ltmIncome: number
  track_streamsTotal: number
  track_streamDate: number
  track_album_releaseDate: number
  track_ltm: number
  [key: string]: number
}

export const initialConfirmedOfferSongSelectionSortSort: ConfirmedOfferSongSelectionSort = {
  track_title: 0,
  isrc: 0,
  track_albumId: 0,
  selected: 0,
  ownership: 0,
  advanceOfferUsd: 0,
  buyoutOfferUsd: 0,
  ltmIncome: 0,
  track_streamsTotal: 0,
  track_streamDate: 0,
  track_ltm: 0,
  track_album_releaseDate: 0
}

export type ReportUploadColumn = {
  id?: number
  type?: string
  header?: string
}

export type AgreementUploadFilterType = {
  artistId?: number
}
